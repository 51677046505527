// @ts-nocheck
import React from 'react';
import { useIntl, injectIntl } from 'gatsby-plugin-intl';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

const NotFoundPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>{intl.formatMessage({ id: 'notfound.title' })}</h1>
      <p>{intl.formatMessage({ id: 'notfound.text' })}</p>
    </Layout>
  );
};

export default injectIntl(NotFoundPage);
